// Libraries
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Misc
import "./RedBlueTextBox.css";

// Exports
export const redBlueTextBoxClassName = "red-blue-text-box";
export const blueTextBoxClassName = "blue-text-box";
export const redTextBoxClassName = "red-text-box";

// Constants
const redBoxOffsetPx = 10;
const redBlueTextBoxClassBase =`
    relative
    flex
    justify-center
    items-center
` + redBlueTextBoxClassName;

export default function RedBlueTextBox(props) {
    // Determine class
    let redBlueTextBoxClass = redBlueTextBoxClassBase;
    redBlueTextBoxClass += " " + props.additionalClass;

    // Helpers
    const positionRedBoxes = () => {
        const redBlueTextBoxes = document.querySelectorAll(`.${redBlueTextBoxClassName}`);
        const blueBoxes = document.querySelectorAll(`.${blueTextBoxClassName}`);
        const redBoxes = document.querySelectorAll(`.${redTextBoxClassName}`);
        blueBoxes.forEach((blueBox, index) => {
            const blueBoxRect = blueBox.getBoundingClientRect();
            const redBlueTextBox = redBlueTextBoxes[index];
            const redBlueTextBoxRect = redBlueTextBox.getBoundingClientRect();
            const topOrigin = blueBoxRect.top - redBlueTextBoxRect.top;
            const leftOrigin = blueBoxRect.left - redBlueTextBoxRect.left;
            const redBox = redBoxes[index];
            redBox.style.top = `${topOrigin + redBoxOffsetPx}px`;
            redBox.style.left = `${leftOrigin + redBoxOffsetPx}px`;
            redBox.style.width = `${blueBoxRect.width}px`;
            redBox.style.height = `${blueBoxRect.height}px`;
        });
    };

    // Window resize event handler
    const handleWindowResize = () => {
        positionRedBoxes();
    };

    useEffect(() => {
        // Add event listeners
        window.addEventListener("resize", handleWindowResize);

        // Position elements
        positionRedBoxes();

        // Cleanup
        return () => {
            // Add event listeners
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    return (
        <div className={redBlueTextBoxClass}>
            {/* Red Box */}
            <div className={`
                w-19/20
                absolute
                bg-logoRed
                rounded-3xl
                z-0 
                shadow-darkGray
                shadow-md ` + redTextBoxClassName} />

            {/* Blue Box */}
            <div className={`
                blue-text-box
                w-19/20
                bg-logoBlue
                rounded-3xl
                overflow-y-auto
                p-5
                z-10 ` + blueTextBoxClassName}>

                {/* Content */}
                {props.children}
            </div>
        </div>
    );
}

RedBlueTextBox.propTypes = {
    children: PropTypes.object,
    additionalClass: PropTypes.string
};
