// Libraries
import React, { useContext } from "react";
import ImageGallery from "react-image-gallery";

// Components
import CurrentSponsors from "../CurrentSponsors/CurrentSponsors";

// Contexts
import { SelectPageContext } from "../../Contexts/SelectPageContext";

// Misc
import { headerTextClass, whiteButtonClass } from "../../utils/tailwindExtensions";
import { aboutUsPageName, getInvolvedPageName } from "../../utils/constants";
import "./Home.css";

// Assets 
import image1 from "../../assets/carousel_images/3.jpg";
import image2 from "../../assets/carousel_images/5.jpg";
import image3 from "../../assets/carousel_images/6.jpg";
import image4 from "../../assets/carousel_images/7.jpg";
import image5 from "../../assets/carousel_images/9.jpg";
import image6 from "../../assets/carousel_images/10.jpg";
import image7 from "../../assets/carousel_images/11.jpg";
import image9 from "../../assets/carousel_images/13.jpg";
import image10 from "../../assets/carousel_images/14.jpg";
import image12 from "../../assets/carousel_images/16.jpg";
import image14 from "../../assets/carousel_images/18.jpg";
import image15 from "../../assets/carousel_images/19.jpg";
import image16 from "../../assets/carousel_images/20.jpg";

// Constants
const riverHawkRacingId = "river-hawk-racing";
const imageClass = `
    hover:cursor-default
    h-80
    md:h-96
    lg:h-164
`;
const imageSources = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image9,
    image10,
    image12,
    image14,
    image15,
    image16
];
const images = imageSources.map(image => {
    return {
        original: image,
        originalClass: imageClass
    };
});

export default function Home() {
    // Contexts
    const selectPage = useContext(SelectPageContext);
    return (
        <div className="
            app-body">
            {/* Carousel Container */}
            <div className="
                w-full
                relative
                bg-darkGray">
                
                {/* Carousel */}
                <ImageGallery 
                    items={images} 
                    autoPlay={true} 
                    slideInterval={3000}
                    slideDuration={600}
                    showBullets={true} />
            
                {/* River Hawk Racing */}
                <div id={riverHawkRacingId} className={`
                    absolute
                    w-full
                    h-full
                    top-0
                    left-0
                    p-10
                    flex
                    flex-col
                    justify-center
                    items-center`}>
                    <p className={headerTextClass}>River Hawk Racing</p>
                    <p className="
                        mt-5
                        sm:mt-6
                        md:mt-8
                        lg:mt-16
                        w-4/5
                        md:w-3/5
                        text-xl
                        sm:text-2xl
                        md:text-3xl
                        lg:text-4xl
                        xl:text-5xl
                        italic
                        text-white
                        text-center">
                        Bringing students from the classroom to the racetrack
                    </p>
                </div>
            </div>

            {/* Call-to-Action Buttons */}
            <div className="
                w-full
                h-24
                flex
                justify-center
                items-center
                bg-logoBlue">
                {/* "About Us" Button */}
                <button 
                    className={whiteButtonClass + " mr-5"}
                    onClick={() => selectPage(aboutUsPageName)}>
                    About Us →
                </button>

                {/* "Get Involved" Button */}
                <button 
                    className={whiteButtonClass + " ml-5"}
                    onClick={() => selectPage(getInvolvedPageName)}>
                    Get Involved →
                </button>
            </div>

            {/* Sponsors */}
            <CurrentSponsors />
        </div>
    );
}
