// Libraries
import React from "react";
import { IconContext } from "react-icons/lib";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { GrMail } from "react-icons/gr";

// Misc
import { textBoxTextClass } from "../../utils/tailwindExtensions";
import { gmailLink, instagramLink, facebookLink } from "../../utils/constants";
import { copyText } from "../../utils/helpers";

// Exports
export const footerId = "footer";

export default function Footer() {
    return (
        <IconContext.Provider value={{
            className: `
                text-white
                text-3xl
                duration-200
                hover:text-logoRed
                hover:cursor-pointer
            `
        }}>
            <div id={footerId} className="
                w-full
                h-36
                bg-logoBlue
                flex
                flex-col
                justify-center
                items-center">
                {/* Icons */}
                <div className="
                    w-48
                    flex
                    justify-evenly
                    items-center">
                    {/* Gmail */}
                    <a onClick={() => copyText(gmailLink)}>
                        <GrMail />
                    </a>

                    {/* Instagram */}
                    <a href={instagramLink} target="_blank" rel="noreferrer">
                        <AiFillInstagram />
                    </a>

                    {/* Facebook */}
                    <a href={facebookLink} target="_blank" rel="noreferrer">
                        <AiFillFacebook />
                    </a>
                </div>

                {/* Copyright */}
                <p className={textBoxTextClass + " mt-5"}>
                    River Hawk Racing © 2022
                </p>
            </div>
        </IconContext.Provider>
    );
}
