// Libraries
import React from "react";

// Components
import HeaderImage from "../HeaderImage/HeaderImage";
import RedBlueTextBox from "../RedBlueTextBox/RedBlueTextBox";

// Misc
import { textBoxHeaderClass, textBoxTextClass } from "../../utils/tailwindExtensions";
import { copyText } from "../../utils/helpers";
import { gmailLink } from "../../utils/constants";
import "./GetInvolved.css";

// Assets
import headerImage from "../../assets/carousel_images/8.jpg";

// Constants
const textBoxClass = `
    my-6
    w-11/12
    sm:w-5/6
    md:w-2/3
    lg:w-5/12
    opacity-0
`;
const firstTextBoxClass = `
    first-text-box
    ${textBoxClass}
    sm:mr-12
    md:mr-20
    lg:mr-48
`;
const secondTextBoxClass = `
    second-text-box
    ${textBoxClass}
    sm:ml-12
    md:ml-20
    lg:ml-48
    mb-12
`;
const textBoxContainerClass = `
    flex
    flex-col
    justify-start
    items-center
    text-center
`;
const linkClass = `
    ${textBoxTextClass}
    underline
    hover:cursor-pointer
    hover:text-logoRed
`;

export default function GetInvolved() {
    return (
        <div className="app-body">
            {/* Header Image */}
            <HeaderImage imageSource={headerImage} headerText="Get Involved" />

            {/* Prospective Sponsors */}
            <RedBlueTextBox additionalClass={firstTextBoxClass}>
                <div className={textBoxContainerClass}>
                    {/* Header */}
                    <h1 className={textBoxHeaderClass}>
                        Prospective Sponsors
                    </h1>

                    {/* Text */}
                    <a className={linkClass} onClick={() => copyText(gmailLink)}>
                        Reach out to us
                    </a>
                </div> 
            </RedBlueTextBox>

            {/* Potential Members */}
            <RedBlueTextBox additionalClass={secondTextBoxClass}>
                <div className={textBoxContainerClass}>
                    {/* Header */}
                    <h1 className={textBoxHeaderClass}>
                        Potential Members
                    </h1>

                    {/* Text */}
                    <a className={linkClass} onClick={() => copyText(gmailLink)}>
                        Reach out to us
                    </a>
                </div>
            </RedBlueTextBox>
        </div>
    );
}
