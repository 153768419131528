// Tailwind class for page headers
export const headerTextClass = `
    text-center
    text-white
    text-5xl
    md:text-6xl
    lg:text-7xl
    xl:text-8xl  
`;

// Tailwind class for section headers
export const sectionHeaderTextClass = `
    text-center
    font-thin
    text-black
    text-5xl
    lg:text-6xl
    xl:text-7xl  
    mt-12
    mb-6
    mx-6
`;

// Tailwind class for text box headers
export const textBoxHeaderClass = `
    text-white
    font-light
    text-3xl
    sm:text-4xl
    mb-3
`;

// Tailwind class for text box text
export const textBoxTextClass = `
    text-white
    text-lg
    sm:text-xl
    lg:text-2xl
`;

// Base class for all buttons
const buttonClassBase = `
    ${textBoxTextClass}
    border-2
    rounded-xl
    p-3
    duration-200
`;

// Tailwind class for white buttons
export const whiteButtonClass = `
    ${buttonClassBase}
    text-logoBlue
    bg-white
    border-white
    hover:text-logoRed
`;

// Tailwind class for blue buttons
export const blueButtonClass = `
    ${buttonClassBase}
    text-white
    bg-logoBlue
    border-logoBlue
`;
