// Libraries
import React from "react";

// Components
import HeaderImage from "../HeaderImage/HeaderImage";
import RedBlueTextBox from "../RedBlueTextBox/RedBlueTextBox";

// Misc
import { textBoxHeaderClass } from "../../utils/tailwindExtensions";
import { gmailLink, instagramLink, facebookLink } from "../../utils/constants";
import Contact from "../../utils/Contact";
import { copyText } from "../../utils/helpers";
import "./ContactUs.css";

// Assets
import headerImage from "../../assets/header_images/ContactUsHeader.jpg";

// Constants
const contacts = [
    new Contact("Email", gmailLink, true),
    new Contact("Instagram", instagramLink),
    new Contact("Facebook", facebookLink)
];
const nameClass = `
    ${textBoxHeaderClass}
    underline
    hover:text-logoRed
    hover:cursor-pointer
`;

export default function ContactUs() {
    // Assemble names and links
    const namesAndLinks = [];
    contacts.forEach((contact, index) => {
        // Determine href and target
        let href = null;
        let target = null;
        let handleClick = null;
        if (!contact.copyOnClick) {
            href = contact.link;
            target = "_blank";
        } else {
            handleClick = () => {
                copyText(contact.link);
            };
        }

        // Append link
        namesAndLinks.push(
            <li key={index} className="
                mx-12">
                <a className={nameClass} href={href} target={target} rel="noreferrer" onClick={handleClick}>
                    {contact.name}
                </a>
            </li>
        );
    });

    return (
        <div className="app-body">
            {/* Header Image */}
            <HeaderImage imageSource={headerImage} headerText="Contact Us" additionalClass="!bg-bottom" />

            {/* Container */}
            <RedBlueTextBox additionalClass="
                contact-box
                opacity-0
                my-6">
                <ul className="
                    flex
                    flex-col
                    justify-between
                    items-center
                    h-48">
                    {namesAndLinks}
                </ul>
            </RedBlueTextBox>
        </div>
    );
}
