// Libraries
import React, { useRef, useEffect } from "react";

// Components
import HeaderImage from "../HeaderImage/HeaderImage";
import RedBlueTextBox from "../RedBlueTextBox/RedBlueTextBox";

// Misc
import { blueTextBoxClassName } from "../RedBlueTextBox/RedBlueTextBox";
import { sm, md, lg } from "../../utils/constants";
import "./AboutUs.css";

// Assets
import headerImage from "../../assets/header_images/AboutUsHeader.jpg";

// Constants
const containerClassName = "text-box-container";
const firstContainerClassName = "first-container";
const secondContainerClassName = "second-container";
const thirdContainerClassName = "third-container";
const firstPathId = "first-path";
const secondPathId = "second-path";
const textBoxClass = `
    w-11/12
    sm:w-2/3
    md:w-1/2
    mb-6
    sm:mb-10
    md:mb-12
`;
const textBoxContainerClassBase = `
    ${containerClassName}
    opacity-0
    w-full
    flex
    justify-center
    items-center
    z-10
`;
const firstThirdContainerClass = `
    sm:justify-start
    sm:ml-20
    md:ml-48
`;
const firstContainerClass = `
    ${textBoxContainerClassBase}
    ${firstThirdContainerClass}
    ${firstContainerClassName}
    mt-8
`;
const secondContainerClass = `
    ${textBoxContainerClassBase}
    ${secondContainerClassName}
    sm:justify-end
    sm:mr-20
    md:mr-48
`;
const thirdContainerClass = `
    ${textBoxContainerClassBase}
    ${firstThirdContainerClass}
    ${thirdContainerClassName}
`;
const pathClassBase = `
    hidden
    sm:block
    opacity-0
    absolute
    border-logoRed
    border-t-8
    rounded-3xl
    z-0
`;
const textClass = `
    text-white
    text-lg
    sm:text-xl
    lg:text-2xl
`;

export default function AboutUs() {
    // State
    const firstPathRef = useRef(null);
    const secondPathRef = useRef(null);
    const textBoxes = useRef(null);
    const textBoxContainersRef = useRef(null);

    // Helpers
    const sizeAndPositionPaths = () => {
        // Retrieve elements
        const firstTextBox = textBoxes.current[0];
        const firstTextBoxRect = firstTextBox.getBoundingClientRect();
        const secondTextBox = textBoxes.current[1];
        const secondTextBoxRect = secondTextBox.getBoundingClientRect();
        const thirdTextBox = textBoxes.current[2];
        const thirdTextBoxRect = thirdTextBox.getBoundingClientRect();
        const firstPath = firstPathRef.current;
        const secondPath = secondPathRef.current;
        const verticalSpacing = secondTextBoxRect.top - firstTextBoxRect.bottom;
        const margin = window.innerWidth < sm
            ? 100
            : window.innerWidth < md
                ? 125
                : window.innerWidth < lg
                    ? 150 
                    : 200;

        // First path 
        firstPath.style.width = `${firstTextBoxRect.width / 2 + margin}px`;
        firstPath.style.height = `${firstTextBoxRect.height / 2 + verticalSpacing + secondTextBoxRect.height / 2}px`;
        firstPath.style.top = `${window.scrollY + firstTextBoxRect.top + firstTextBoxRect.height / 2}px`;
        firstPath.style.left = `${firstTextBoxRect.left + firstTextBoxRect.width / 2}px`;

        // Second path
        secondPath.style.width = `${secondTextBoxRect.width / 2 + margin}px`;
        secondPath.style.height = `${secondTextBoxRect.height / 2 + verticalSpacing + thirdTextBoxRect.height / 2}px`;
        secondPath.style.top = `${window.scrollY + secondTextBoxRect.top + secondTextBoxRect.height / 2}px`;
        secondPath.style.left = `${secondTextBoxRect.left - margin}px`;
    };

    // Window resize event handler
    const handleWindowResize = () => {
        sizeAndPositionPaths();
    };

    useEffect(() => {
        // Add event listeners
        window.addEventListener("resize", handleWindowResize);

        // Cache HTML elements
        firstPathRef.current = document.getElementById(firstPathId);
        secondPathRef.current = document.getElementById(secondPathId);
        textBoxes.current = document.querySelectorAll(`.${blueTextBoxClassName}`);
        textBoxContainersRef.current = document.querySelectorAll(`.${containerClassName}`);

        // Initialize HTML elements
        sizeAndPositionPaths();

        // Cleanup
        return () => {
            // Remove event listeners
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    // Determine classes
    const firstPathClass = pathClassBase + `
        border-r-8
    `;
    const secondPathClass = pathClassBase + `
        border-l-8
    `;

    return (
        <div className="
            app-body
            overflow-x-hidden">
            {/* Header Image */}
            <HeaderImage imageSource={headerImage} headerText="About Us" />

            {/* First Text Box */}
            <div className={firstContainerClass}>
                <RedBlueTextBox additionalClass={textBoxClass}>
                    <p className={textClass}>
                        River Hawk Racing strives to push students beyond their textbooks: to apply the knowledge they learn in their college career in a challenging project as they would in a real industry position.
                    </p>
                </RedBlueTextBox>
            </div>

            {/* Path from First to Second Text Box */}
            <div id={firstPathId} className={firstPathClass} />

            {/* Second Text Box */}
            <div className={secondContainerClass}>
                <RedBlueTextBox additionalClass={textBoxClass}>
                    <p className={textClass}>
                        Students learn valuable ingenuity that can only come from a real-world project such as the Formula Society of Automotive Engineers International Competition.
                    </p>
                </RedBlueTextBox>
            </div>

            {/* Path from Second to Third Text Box */}
            <div id={secondPathId} className={secondPathClass} />

            {/* Third Text Box */}
            <div className={thirdContainerClass}>
                <RedBlueTextBox additionalClass={textBoxClass}>
                    <p className={textClass}>
                    Design skills, teamwork, and managing a complex project go beyond what is taught in the classroom. With the global challenges of today, it is imperative to continue to produce highly skilled and adaptable students from all disciplines.
                    </p>
                </RedBlueTextBox>
            </div>
        </div>
    );
}
