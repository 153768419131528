export const sm = 640;
export const md = 768;
export const lg = 1024;
export const homePageName = "Home";
export const aboutUsPageName = "About Us";
export const sponsorsPageName = "Sponsors";
export const getInvolvedPageName = "Get Involved";
export const contactUsPageName = "Contact Us";
export const gmailLink = "riverhawkracing@gmail.com";
export const instagramLink = "https://www.instagram.com/riverhawkracing/";
export const facebookLink = "https://www.facebook.com/RiverHawkRacing/";
