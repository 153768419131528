// Libraries
import React, { useContext } from "react";

// Components
import HeaderImage from "../HeaderImage/HeaderImage";
import RedBlueTextBox from "../RedBlueTextBox/RedBlueTextBox";
import CurrentSponsors from "../CurrentSponsors/CurrentSponsors";

// Contexts
import { SelectPageContext } from "../../Contexts/SelectPageContext";

// Misc
import { sectionHeaderTextClass, textBoxTextClass, whiteButtonClass } from "../../utils/tailwindExtensions";
import { getInvolvedPageName } from "../../utils/constants";
import "./Sponsors.css";

// Assets
import headerImage from "../../assets/header_images/SponsorsHeader.jpg";

// Constants
const textClass = `
    ${textBoxTextClass}
    text-center
    mb-6
`;

export default function Sponsorships() {
    // Contexts
    const selectPage = useContext(SelectPageContext);

    return (
        <div className="app-body">
            {/* Header Image */}
            <HeaderImage imageSource={headerImage} headerText="Sponsors" />

            {/* "Prospective Sponsors" Header */}
            <h1 className={sectionHeaderTextClass + " prospective-sponsors opacity-0"}>
                Prospective Sponsors
            </h1>

            {/* "Prospective Sponsors" Text Box */}
            <RedBlueTextBox additionalClass="
                prospective-sponsors-content
                opacity-0
                my-6
                w-11/12
                sm:w-5/6
                md:w-2/3
                lg:w-5/12">
                <div className="
                    flex
                    flex-col
                    justify-start
                    items-center">
                    <p className={textClass}>
                        Without our generous sponsors, River Hawk Racing would not be able to provide the quality hands on learning and experience that it does.
                    </p>
                    <button 
                        className={whiteButtonClass}
                        onClick={() => selectPage(getInvolvedPageName)}>
                        Sponsorship Packages →
                    </button>
                </div>
            </RedBlueTextBox>

            {/* Current Sponsors */}
            <CurrentSponsors />
        </div>
    );
}
