// Libraries
import React from "react";

// Misc
import ImageBundle from "../../utils/ImageBundle";
import { sectionHeaderTextClass } from "../../utils/tailwindExtensions";

// Assets
import autodeskLogo from "../../assets/sponsors/Autodesk.png";
import cohesionLogo from "../../assets/sponsors/Cohesion.png";
import emtronLogo from "../../assets/sponsors/Emtron.png";
import fkBearingsLogo from "../../assets/sponsors/FKBearings.png";
import francisLogo from "../../assets/sponsors/Francis.png";
import hexagonLogo from "../../assets/sponsors/Hexagon.png";
import kenestoLogo from "../../assets/sponsors/Kenesto.png";
import manningLogo from "../../assets/sponsors/Manning.png";
import mechELogo from "../../assets/sponsors/MechE.png";
import parkerLogo from "../../assets/sponsors/Parker.png";
import saeLogo from "../../assets/sponsors/SAE.png";
import sgaLogo from "../../assets/sponsors/SGA.png";
import skyworksLogo from "../../assets/sponsors/Skyworks.png";
import solidWorksLogo from "../../assets/sponsors/SolidWorks.png";
import specialtyMaterialsLogo from "../../assets/sponsors/SpecialtyMaterials.png";

// Constants
const imageClass = `
    w-full
    flex
    justify-center
    items-center
`;
const imageBundles = [
    new ImageBundle(autodeskLogo, "Autodesk Logo"),
    new ImageBundle(cohesionLogo, "Cohesion Motorsports Logo"),
    new ImageBundle(emtronLogo, "Emtron Logo"),
    new ImageBundle(fkBearingsLogo, "FK Bearings Logo"),
    new ImageBundle(francisLogo, "Francis College of Engineering Logo"),
    new ImageBundle(hexagonLogo, "Hexagon Logo"),
    new ImageBundle(kenestoLogo, "Kenesto Logo"),
    new ImageBundle(manningLogo, "Manning School of Business Logo"),
    new ImageBundle(mechELogo, "UMass Lowell Mechanical Engineering Logo"),
    new ImageBundle(parkerLogo, "Parker Chomerics"),
    new ImageBundle(saeLogo, "SAE International Logo"),
    new ImageBundle(sgaLogo, "UMass Lowell Student Government Association Logo"),
    new ImageBundle(skyworksLogo, "Skyworks Logo"),
    new ImageBundle(solidWorksLogo, "SolidWorks Logo"),
    new ImageBundle(specialtyMaterialsLogo, "Specialty Materials Logo")
];

export default function CurrentSponsors() {
    // Assemble images
    const images = imageBundles.map((imageBundle, index) => {
        return (
            <div key={index} className="
                w-full
                h-full
                flex
                justify-center
                items-center
            ">
                <img className={imageClass} src={imageBundle.src} alt={imageBundle.alt} />
            </div>
        );
    });

    return (
        <>
            {/* "Current Sponsors" Header */}
            <h1 className={sectionHeaderTextClass}>
                Our Sponsors
            </h1>

            {/* "Current Sponsors" Grid */}
            <div className="
                w-11/12
                border
                border-lightGray
                rounded-xl
                my-6
                p-5
                grid
                grid-cols-1
                sm:grid-cols-2
                md:grid-cols-3
                lg:grid-cols-4
                gap-5
                shadow-md
                shadow-lightGray">
                {images}
            </div>
        </>
    );
}
