// Libraries
import React, { useState, useEffect } from "react";

// Components
import Page from "./utils/Page";
import Nav from "./Components/Nav/Nav";
import HamburgerMenu from "./Components/HamburgerMenu/HamburgerMenu";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Sponsors from "./Components/Sponsors/Sponsors";
import GetInvolved from "./Components/GetInvolved/GetInvolved";
import ContactUs from "./Components/ContactUs/ContactUs";
import Footer from "./Components/Footer/Footer";

// Contexts
import { SelectPageContext } from "./Contexts/SelectPageContext";

// Misc
import { navId } from "./Components/Nav/Nav";
import { footerId } from "./Components/Footer/Footer";
import { 
    homePageName, 
    aboutUsPageName,
    sponsorsPageName,
    getInvolvedPageName,
    contactUsPageName
} from "./utils/constants";

// Constants
const pageList = [
    new Page(homePageName, <Home />),
    new Page(aboutUsPageName, <AboutUs />),
    new Page(sponsorsPageName, <Sponsors />),
    new Page(getInvolvedPageName, <GetInvolved />),
    new Page(contactUsPageName, <ContactUs />),
];
const defaultPageName = homePageName;

export default function App() {
    // State
    let [selectedPageName, setSelectedPageName] = useState(defaultPageName);
    let [showingHamburgerMenu, setShowingHamburgerMenu] = useState(false);

    // Helpers
    const selectPage = (pageName) => {
        // Validate page
        if (pageList.filter(page => page.name === pageName).length === 0) {
            return;
        }

        // Select the new page and scroll to the top of the document 
        window.scrollTo(0, 0);
        setSelectedPageName(pageName);
    };
    const toggleShowingHamburgerMenu = () => {
        setShowingHamburgerMenu(!showingHamburgerMenu);
    };
    const setAppBodyHeight = () => {
        // Retrieve and validate all relevant elements
        const appBody = document.querySelector(".app-body");
        const nav = document.getElementById(navId);
        const footer = document.getElementById(footerId);
        if (!appBody || !nav || !footer) {
            return;
        }

        // Determine proper app body height
        const navHeight = nav.getBoundingClientRect().height;
        const footerHeight = footer.getBoundingClientRect().height;
        appBody.style = `min-height: ${window.innerHeight - navHeight - footerHeight}px;`;
    };
    const getAppBodyComponent = (pageName) => {
        return pageList.filter(page => page.name === pageName)[0].component;
    };

    // Scroll event handler
    const handleScroll = () => {
        setAppBodyHeight();
    };

    // Window resize event handler
    const handleWindowResize = () => {
        setAppBodyHeight();
    };

    useEffect(() => {
        // Add event listeners
        window.addEventListener("resize", handleWindowResize);
        window.addEventListener("scroll", handleScroll);

        // Set element size
        setAppBodyHeight();

        // Cleanup
        return () => {
            // Remove event listeners
            window.removeEventListener("resize", handleWindowResize);
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <SelectPageContext.Provider value={selectPage}>
            <div className="App">
                {/* Nav Bar */}
                <Nav 
                    pageList={pageList}
                    selectedPageName={selectedPageName} 
                    showingHamburgerMenu={showingHamburgerMenu}
                    toggleShowingHamburgerMenu={toggleShowingHamburgerMenu} />

                {/* Hamburger Menu */}
                {showingHamburgerMenu && <HamburgerMenu 
                    pageList={pageList} 
                    selectedPageName={selectedPageName}
                    toggleShowingHamburgerMenu={toggleShowingHamburgerMenu} />}

                {/* Selected Page */}
                {getAppBodyComponent(selectedPageName)}

                {/* Footer */}
                <Footer />
            </div>
        </SelectPageContext.Provider>
    );
}
