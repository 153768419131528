// Libraries
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// Contexts
import { SelectPageContext } from "../../Contexts/SelectPageContext";

// Assets
import logo from "../../assets/logo.png";

// Exports
export const navId = "nav";

// Constants
const navClassBase = `
    sticky
    top-0
    left-0
    w-full
    px-4
    bg-logoBlue
    flex
    justify-between
    items-center
    shadow-black
    z-50
    duration-200
`;
const logoClassBase = `
    h-24
    duration-200
`;
const navItemsClassBase = `
    h-full
    hidden
    justify-center
    items-center
`;
const navItemClassBase = `
    text-white
    text-xl
    ml-5
    lg:ml-8
    hover:cursor-pointer
    p-2
    duration-200
    border-2
    rounded-xl
`;
const hamburgerClassBase = `
    h-16
    w-16
    flex
    flex-col
    justify-evenly
    items-center
    duration-200
    group
    border-logoBlue
    border-2
    rounded-xl
    hover:cursor-pointer
    hover:border-white
`;
const hamburgerBarClassBase = `
    w-10/12
    h-1.5
    bg-white
    rounded-full
    duration-200
`;

export default function Nav(props) {
    // State
    let [scrollPosition, setScrollPosition] = useState(0);

    // Contexts
    const selectPage = useContext(SelectPageContext);

    // Nav items
    const navItems = props.pageList.map((page, index) => {
        // Determine nav item class
        let navItemClass = navItemClassBase;
        navItemClass += page.name === props.selectedPageName
            ? " bg-white text-logoBlue"
            : " border-logoBlue text-white hover:border-white";

        return (
            <li className={navItemClass}
                key={index} 
                onClick={() => selectPage(page.name)}>
                {page.name}
            </li>
        );
    });

    // Initialize concrete classes
    let navClass = navClassBase; 
    let navItemsClass = navItemsClassBase;
    let logoClass = logoClassBase;
    let hamburgerClass = hamburgerClassBase;
    let firstHamburgerBarClass = hamburgerBarClassBase;
    let secondHamburgerBarClass = hamburgerBarClassBase;
    let thirdHamburgerBarClass = hamburgerBarClassBase;

    // Hamburger menu modifications
    if (props.showingHamburgerMenu) {
        logoClass += " opacity-0";
        firstHamburgerBarClass += " rotate-45 translate-y-4";
        secondHamburgerBarClass += " opacity-0";
        thirdHamburgerBarClass += " -rotate-45 -translate-y-4";
    } else {
        navClass += " shadow-md md:justify-evenly";
        navItemsClass += " md:flex";
        hamburgerClass += " md:hidden";
    }

    // Scroll position modifications 
    if (scrollPosition > 0) {
        navClass += " h-20";
        logoClass += " h-20";
    } else {
        navClass += " h-24";
        logoClass += " h-24";
    }

    // Scroll event handler
    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
    };

    useEffect(() => {
        // Add event listeners
        window.addEventListener("scroll", handleScroll);

        // Cleanup
        return () => {
            // Remove event listeners
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <div id={navId} className={navClass}>
            {/* Logo */}
            <a href="/">
                <img src={logo} alt="assets" className={logoClass} />
            </a>

            {/* Nav Items */}
            <ul className={navItemsClass}>
                {navItems}
            </ul>

            {/* Hamburger */}
            <div className={hamburgerClass} onClick={props.toggleShowingHamburgerMenu}>
                <div className={firstHamburgerBarClass}></div>
                <div className={secondHamburgerBarClass}></div>
                <div className={thirdHamburgerBarClass}></div>
            </div>
        </div>
    );
}

Nav.propTypes = {
    pageList: PropTypes.array.isRequired,
    selectedPageName: PropTypes.string.isRequired,
    showingHamburgerMenu: PropTypes.bool.isRequired,
    toggleShowingHamburgerMenu: PropTypes.func.isRequired
};
