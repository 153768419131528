// Libraries
import React, { useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// Contexts
import { SelectPageContext } from "../../Contexts/SelectPageContext";

// Misc
import "./HamburgerMenu.css";

// Constants
const navItemClassBase = `
    text-2xl
    text-white
    hover:cursor-pointer
    p-2
    duration-200
    border-2
    rounded-xl
`;

export default function HamburgerMenu(props) {
    // State
    const initialScrollPositionRef = useRef(0);

    // Contexts
    const selectPage = useContext(SelectPageContext);

    // Helpers
    const handleNavItemClick = (page) => {
        // Override the cached scroll position to automatically scroll to the top of the page
        initialScrollPositionRef.current = 0;

        // Select the new page and hide the hamburger menu
        selectPage(page);
        props.toggleShowingHamburgerMenu();
    };

    // Nav items
    const navItems = props.pageList.map((page, index) => {
        // Determine nav item class
        let navItemClass = navItemClassBase;
        navItemClass += page.name === props.selectedPageName
            ? " bg-white text-logoBlue"
            : " border-logoBlue text-white hover:border-white";

        return (
            <li 
                key={index} 
                className={navItemClass}
                onClick={() => handleNavItemClick(page.name)}>
                {page.name}
            </li>
        );
    });

    useEffect(() => {
        // Cache the initial scroll position
        initialScrollPositionRef.current = window.pageYOffset;

        // Scroll to the top of the page
        window.scrollTo(0, 0);

        // Disable scroll
        document.body.style.overflowY = "hidden";

        // Cleanup
        return () => {
            // Enable scroll
            document.body.style.overflowY = "visible";

            // Move back to the initial scroll position
            window.scrollTo(0, initialScrollPositionRef.current);
        };
    });

    return (
        <div className="
            absolute
            w-screen
            h-screen
            top-0
            left-0
            bg-logoBlue
            z-40
            flex
            flex-col
            justify-start
            items-center"
        id="hamburger-menu">
            <ul className="
                h-full
                w-full
                my-36
                flex
                flex-col
                justify-between
                items-center">
                {navItems}
            </ul>
        </div>
    );
}

HamburgerMenu.propTypes = {
    pageList: PropTypes.array.isRequired,
    selectedPageName: PropTypes.string.isRequired,
    toggleShowingHamburgerMenu: PropTypes.func.isRequired 
};
