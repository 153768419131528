// Libraries
import React from "react";
import PropTypes from "prop-types";

// Misc
import { headerTextClass } from "../../utils/tailwindExtensions";
import "./HeaderImage.css";

// Constants
const imageOverlayClassName = "header-image-overlay";

export default function HeaderImage(props) {
    return (
        <div className="
            w-full">
            {/* Image */}
            <div style={{ backgroundImage: `url(${props.imageSource})` }} className={`
                bg-no-repeat
                bg-cover
                bg-center
                w-full
                h-64
                sm:h-72
                md:h-80
                ${props.additionalClass}`}>
                {/* Overlay */}
                <div className={`
                    ${imageOverlayClassName}
                    w-full
                    h-full
                    flex
                    justify-center
                    items-center`}>
                    {/* Header Text */}
                    <h1 className={headerTextClass}>
                        {props.headerText}
                    </h1>
                </div>
            </div>
        </div>
    );
}

HeaderImage.propTypes = {
    imageSource: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    additionalClass: PropTypes.string
};
